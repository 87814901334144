
import { CommonServiceUtils } from "services/common-service-utils";
export class AffiliateProgramService extends CommonServiceUtils {
  async getRewards(req: any): Promise<any> {
    const getRewards: any = await this.postData(
      this.apiUrlCreator(`Reward/Filter`),
      req
    );

    return getRewards;
  }

  async getRewardTypes(): Promise<any> {
    let response = [{
      "RewardTypeId": 1,
      "RewardTypeName": "Cash Reward"
  }];
    return response;
  }
  async saveReward(req: any): Promise<any> {
    const saveReward: any = await this.postData(
      this.apiUrlCreator(`Reward/Create`),
      req
    );
    return saveReward;
  }

  async DeleteReward(req: any): Promise<any> {
    const DeleteReward: any = await this.postData(
      this.apiUrlCreator(`Reward/delete?rewardId=${req?.RewardId}`)
    );
    return DeleteReward;
  }
  async updateReward(req: any): Promise<any> {
    const updateReward: any = await this.postData(
      this.apiUrlCreator(`Reward/Update`),
      req
    );
    return updateReward;
  }

  async getPromoters(req:any): Promise<any> {
    let response = null;
    const getPromoters: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/Filter`),req
    );
    if (getPromoters?.ResponseCode === 100) {
      response = getPromoters;
    }
    return response;
  }

  async getOffers(req: any): Promise<any> {
    let response = [];
    const getOffers: any = await this.postData(
      this.apiUrlCreator(`Coupon/GetPromotionCoupons`),req
    );
    if (getOffers?.ResponseCode === 100) {
      response = getOffers?.Items;
    }
    return response;
  }

  async saveOffer(req: any): Promise<any> {
    const saveOffer: any = await this.postData(
      this.apiUrlCreator(`Coupon/CreatePromotionCoupon`),
      req
    );
    return saveOffer;
  }
  async CompleteAffiliateSignup(req: any): Promise<any> {
    const CompleteAffiliateSignup: any = await this.postData(
      this.apiUrlCreator(`Signup/CompleteAffiliateSignup`),
      req
    );
    return CompleteAffiliateSignup;
  }

  async DeleteOffer(req: any): Promise<any> {
    const DeleteOffer: any = await this.postData(
      this.apiUrlCreator(`Coupon/DeletePromotionCoupon?CouponId=${req?.CouponId}`)
    );
    return DeleteOffer;
  }

  async getPaymentTerms(): Promise<any> {
    let response = [];
    const getPaymentTerms: any = await this.getData(
      this.apiUrlCreator(`Promotion/GetPaymentTerms`)
    );
    if (getPaymentTerms?.ResponseCode === 100) {
      response = getPaymentTerms?.Items;
    }
    return response;
  }
  async getActivationDetails(id:string,isAccepted:boolean): Promise<any> {
    const getActivationDetails: any = await this.postData(
      this.apiUrlCreator(`Signup/CheckAffiliateActivation?userActivationId=${id}&isAccepted=${isAccepted}`)
    );
    return getActivationDetails;
  }
  async getPaymentMethods(): Promise<any> {
    return [
      {
        PayoutMethodId: 1,
        PayoutMethodName: "Authorize",
      },
      {
        PayoutMethodId: 1,
        PayoutMethodName: "Square",
      },
      {
        PayoutMethodId: 1,
        PayoutMethodName: "Stripe",
      },
    ];
  }

  async saveAffiliate(req: any): Promise<any> {
    const saveAffiliate: any = await this.postData(
      this.apiUrlCreator(`Associate/SubmitAffiliate`),
      req
    );
    return saveAffiliate;
  }
  async saveAffiliateProfile(req: any): Promise<any> {
    const saveAffiliateProfile: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/SaveProfile`),
      req
    );
    return saveAffiliateProfile;
  }
  async createPromotion(req: any): Promise<any> {
    const createPromotion: any = await this.postData(
      this.apiUrlCreator(`Promotion/Create`),
      req
    );
    return createPromotion;
  }
  async updatePromotion(req: any): Promise<any> {
    const updatePromotion: any = await this.postData(
      this.apiUrlCreator(`Promotion/UpdatePromotion`),
      req
    );
    return updatePromotion;
  }
  async SendAffiliateActivationEmail(affiliateUserId: any): Promise<any> {
    const SendAffiliateActivationEmail: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/ResendInvite/${affiliateUserId}`)
    );
    return SendAffiliateActivationEmail;
  }

  async getPromotions(req:any): Promise<any> {
    let response = null;
    const getPromotions: any = await this.postData(
      this.apiUrlCreator(`Promotion/Filter`),req
    );
    if (getPromotions?.ResponseCode === 100) {
      response = getPromotions;
    }
    return response;
  }
  async getAffiliateById(associateId:any,req:any): Promise<any> {
    let response=null
    const getAffiliateById: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate?associateId=${associateId}`),req
    );
    if (getAffiliateById?.ResponseCode === 100) {
      response = getAffiliateById?.Item;
    }
    return response;
  }
  async getPromotionById(promotionId:any): Promise<any> {
    let response=null
    const getPromotionById: any = await this.getData(
      this.apiUrlCreator(`Promotion/Get/${promotionId}`)
    );
    if (getPromotionById?.ResponseCode === 100) {
      response = getPromotionById?.Item;
    }
    return response;
  }
  async getPromotionAffiliatesByPromotionId(promotionId:any,req:any): Promise<any> {
    let response=[]
    const getPromotionAffiliatesByPromotionId: any = await this.postData(
      this.apiUrlCreator(`Promotion/${promotionId}/PromotionAffiliate/Filter`),req
    );
    if (getPromotionAffiliatesByPromotionId?.ResponseCode === 100) {
      response = getPromotionAffiliatesByPromotionId?.Items;
    }
    return response;
  }
  async UpdateAfffiliateStatus(req: any): Promise<any> {
    const SendAffiliateActivationEmail: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/UpdateStatus`),req
    );
    return SendAffiliateActivationEmail;
  }

  async UpdatePromotionAffiliatesByPromotionId(promotionId:any,req:any): Promise<any> {
    const UpdatePromotionAffiliatesByPromotionId: any = await this.postData(
      this.apiUrlCreator(`Promotion/${promotionId}/PromotionAffiliate/Update`),req
    );
    return UpdatePromotionAffiliatesByPromotionId;
  }
  async UpdatePromotionSubscriptionsByPromotionId(promotionId:any,req:any): Promise<any> {
    const UpdatePromotionSubscriptionsByPromotionId: any = await this.postData(
      this.apiUrlCreator(`Promotion/${promotionId}/PromotionSubscription/Update`),req
    );
    return UpdatePromotionSubscriptionsByPromotionId;
  }
  async GetPromotionWithAffiliateDetails(req:any): Promise<any> {
    let response=null
    const GetPromotionWithAffiliateDetails: any = await this.getData(
      this.apiUrlCreator(`Promotion/GetPromotionWithAffiliateDetails?promotionCode=${req.promotionCode}&affiliateCode=${req.affiliateCode}&clubId=${req.clubId}`)
    );
    if(GetPromotionWithAffiliateDetails.ResponseCode===100){
      response=GetPromotionWithAffiliateDetails?.Item
    }
    return response
  }

  async GetPromotionWithAffiliateSubscriptionDetails(req:any): Promise<any> {
    let response=null
    const GetPromotionWithAffiliateSubscriptionDetails: any = await this.getData(
      this.apiUrlCreator(`Promotion/GetPromotionWithAffiliateDetails?promotionCode=${req.promotionCode}&affiliateCode=${req.affiliateCode}&clubId=${req.clubId}&subscriptionCode=${req.subscriptionCode}`)
    );
    if(GetPromotionWithAffiliateSubscriptionDetails.ResponseCode===100){
      response=GetPromotionWithAffiliateSubscriptionDetails?.Item
    }
    return response
  }

  async getPromotionPayouts(req:any): Promise<any> {
    let response=null
    const getPromotionPayouts: any = await this.postData(
      this.apiUrlCreator(`PromotionPayout/Filter`),req
    );
    if(getPromotionPayouts.ResponseCode===100){
      response=getPromotionPayouts?.Items[0]
    }
    return response
  }
  async fetchPayoutHistory(PayoutId:number): Promise<any> {
    let response=[]
    const fetchPayoutHistory: any = await this.postData(
      this.apiUrlCreator(`PromotionPayout/${PayoutId}/FetchPayoutHistory`),{}
    );
    if(fetchPayoutHistory.ResponseCode===100){
      response=fetchPayoutHistory?.Items
    }
    return response
  }
  async UpdatePayoutStatus(req:any): Promise<any> {
    const UpdatePayoutStatus: any = await this.postData(
      this.apiUrlCreator(`PromotionPayout/UpdatePayoutStatus`),req
    );
    return UpdatePayoutStatus;
  }
  async UpdatePayoutNotes(req:any): Promise<any> {
    const UpdatePayoutNotes: any = await this.postData(
      this.apiUrlCreator(`PromotionPayout/UpdatePayoutStatus?onlyNotes=true`),req
    );
    return UpdatePayoutNotes;
  }

  async fetchAffiliateProgramSummary(req:any): Promise<any> {
    let response=null
    const fetchAffiliateProgramSummary: any = await this.postData(
      this.apiUrlCreator(`Promotion/GetAffiliateProgramSummary`),req
    );
    if(fetchAffiliateProgramSummary.ResponseCode===100){
      response=fetchAffiliateProgramSummary?.Items[0]
    }
    return response
  }

  async getTopPromotionLinksByAssociateId(req:any): Promise<any> {
    let response=[]
    const getTopPromotionLinksByAssociateId: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/${req?.AffiliateId}/TopPromotionLinks`),req
    );
    if (getTopPromotionLinksByAssociateId?.ResponseCode === 100) {
      response = getTopPromotionLinksByAssociateId?.Items;
    }
    return response;
  }
  async getPromotionActivityForAssociateId(req:any): Promise<any> {
    let response=null
    const getPromotionActivityForAssociateId: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/${req?.AffiliateId}/PromotionActivity`),req
    );
    if (getPromotionActivityForAssociateId?.ResponseCode === 100) {
      response = getPromotionActivityForAssociateId;
    }
    return response;
  }
  async getPromotionActivity(req:any): Promise<any> {
    let response=null
    const getPromotionActivity: any = await this.postData(
      this.apiUrlCreator(`UserAffiliate/PromotionActivity`),req
    );
    if (getPromotionActivity?.ResponseCode === 100) {
      response = getPromotionActivity;
    }
    return response;
  }

  async getPromotionPayoutFetchList(req:any): Promise<any> {
    let response=[]
    const getPromotionPayouts: any = await this.postData(
      this.apiUrlCreator(`PromotionPayout/FetchList`),req
    );
    if(getPromotionPayouts.ResponseCode===100){
      response=getPromotionPayouts?.Items
    }
    return response
  }

  async DeleteAffiliateProfile(userId:any,associateId: any): Promise<any> {
    const DeleteProfile: any = await this.getData(
      this.apiUrlCreator(`UserAffiliate/DeleteProfileImage?userId=${userId}&associateId=${associateId}`)
    );
    return DeleteProfile;
  }

  async getRewardById(rewardId: any): Promise<any> {
    const getRewardData: any = await this.getData(
      this.apiUrlCreator(`Reward?rewardId=${rewardId}`)
    );

    return getRewardData;
  }

}
