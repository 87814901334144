import { Button } from "@progress/kendo-react-buttons";
import { AutoComplete, ListItemProps } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { RadioButton } from "@progress/kendo-react-inputs";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useEffect, useRef, useState } from "react";
import { FormDropDownList, FormInput, FormMaskedTextBox } from "utils/form-components";
import {
  GenderFormValidator,
  GenderSpecifyOthersValidator,
  NameValidator,
  emailValidator,
  mobileValidator,
} from "validators/validator";
import { ClassService } from "services/class/index.service";
import { Dialog } from "@progress/kendo-react-dialogs";
import { GetLocalStore } from "utils/storage";
import { Popup } from "@progress/kendo-react-popup";
import { GenderType } from "utils/form-utils";
interface CustomRadioButtonProps {
  label: string;
  selectedValue: string;
  onChange: (value: string) => void;
}

const AddClient = (props: any) => {
  const IsAffiliateFeatureEnabled =
    GetLocalStore("Configuration")?.IsAffiliateFeatureEnabled;
  const clientCreateRef: any = useRef(null);
  const classService = new ClassService();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showMemberExistsModal, setShowMemberExistsModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [disabledContinue, setDisabledContinue] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isDisableGender,setisDisableGender]=useState(false);
  const [staffDetails, setstaffDetails] = useState(false);
  const [isgenderSpecifyOthers,setIsgenderSpecifyOthers]=useState(false);
  const [selectedValue, setSelectedValue] = useState("pick");
  const [btncontinueLoading, setbtncontinueLoading] = useState(false);
  const buttonRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupposition] = useState({ x: '10px', y: '10px' });

  const getModifiedData = (data: any) => [{ isButton: true }, ...data];
  const modifiedData = getModifiedData(props.clientsList);

  const initialValues = {
    FirstName: props?.selectedClient?.value || '',
    LastName: '',
    PhoneNumber: '',
    Email: ''
  }



  const togglePopup = () => {
    setShowPopup(!showPopup);
    setSelectedValue("pick");
  };

  useEffect(() => {
    if (props?.selectedradioValue === "pick") {
      setSelectedValue("pick");
      setShowPopup(false);
    }
    //props?.onChangeRadioValue();
  }, [props?.selectedradioValue]);

  useEffect(() => {
    if (selectedValue === "new") {
      setShowEmailModal(false);
      setShowMemberExistsModal(false);
      setShowActivateModal(false);
      clientCreateRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedValue]);

  const validateEmail = async (value: string) => {
    const resp = await classService.validateEmail(value);
    setIsStaff(false);
    if (staffDetails && value == "") {
      console.log(1);
      setstaffDetails(false);
      // Clear the form
      if (clientCreateRef?.current) {
        clientCreateRef?.current?.onFormReset();
      }
    }

    if (resp.Item.UserId !== 0) {
      if (resp.Item.UserMemberId > 0) {
        if (resp.Item.UserMemberIsActive) {
          setUserData(resp.Item);
          setShowMemberExistsModal(true);
          //props.setUserId(resp.Item.UserId);
          return false
        } else {
          setUserData(resp.Item);
          setShowActivateModal(true);
          //props.setUserId(resp.Item.UserId);
          return false
        }
      } else {
        setShowEmailModal(true);
        setUserData(resp.Item);
        //props.setUserId(resp.Item.UserId);
        setIsStaff(true);
        return false
      }
    } else {
      setShowEmailModal(false);
      setUserData(undefined);
    }
    return true
  };

  const checkIsStaff = async (value: string) => {
    if (!value) return;
    const resp = await classService.validateEmail(value);
    setIsStaff(false);

    if (resp.Item.UserId !== 0 && resp.Item.UserAssociateId > 0) {
      setIsStaff(true);
    } else {
      setIsStaff(false);
    }
  };

  useEffect(() => {
    checkIsStaff(props?.clientFormValues?.Email);
  }, [props?.clientFormValues?.Email]);

  const activateMember = async () => {
    setbtncontinueLoading(true);
    const resp = await classService.activateMember(userData.UserMemberId);
    if (resp.ResponseCode === 100) {
      //props?.isPaymentForm === false &&
      props.fetchClientData(userData.UserMemberId);
      //props.isPaymentForm === true && props?.updateSelectedClientData(userData);
    } else {
      const successMsg = "Unable to activate member";
      props.setMessage(successMsg);
      props.setNotification({ success: false, error: true });
    }
    setbtncontinueLoading(false);
    setShowActivateModal(false);
  };
  const [timer, setTimer] = useState<any>();

  const handleFieldChange = (event: any, formvalues: FormRenderProps) => {
    const { target, value } = event;
    if(target.name=="Gender"){
      setIsgenderSpecifyOthers(value.text=="Other" ? true: false)
    }
    if(target.name == "GenderSpecifyOthers"){
      const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
      formvalues.onChange("GenderSpecifyOthers", {
        value: sanitizedValue,
      });
    }
    if (target.name === "Email") {
      setDisabledContinue(false);
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        validateEmail(value);
      }, 1000);
      setTimer(newTimer);
    }
  };

  const updateUserFields = (formRenderProps: any) => {
    formRenderProps.onChange("FirstName", {
      value: userData?.FirstName,
    });
    formRenderProps.onChange("LastName", {
      value: userData?.LastName,
    });
    formRenderProps.onChange("PhoneNumber", {
      value: userData?.Phone || "",
    });
    formRenderProps.onChange("UserId", {
      value: userData?.UserId || "",
    });
    const genderValue = userData?.Sex == "M"
      ? { id: 1, text: "Male" }
      : userData?.Sex == "F"
        ? { id: 2, text: "Female" }
        : { id: 0, text: "Please Select Gender" };

    formRenderProps.onChange("Gender", {
      value: genderValue,
    });
    formRenderProps.onChange("Gender", {
      value: genderValue,
    });
    formRenderProps.onChange("GenderSpecifyOthers", {
      value: userData?.GenderSpecifyOthers || "",
    });
    setisDisableGender(userData?.Sex === "M" || userData?.Sex === "F");
    setIsStaff(true);
    setstaffDetails(true);
  };

  const handleAddClient = async (dataItem: any) => {
    // const check=await validateEmail(dataItem.Email)
    // if(check){
    props.handleAddClient(dataItem)
    //}
  }



  const clientCreate = (props: any) => {
    return (
      <div className="p-3">
        <Form
          onSubmit={handleAddClient}
          initialValues={initialValues}
          render={(formRenderProps) => (
            <div>
              <FormElement style={{ width: "100%" }}>
                {showEmailModal && (
                  <Dialog
                    className="bz-selectClient-dialog bz-edit-email-dialog"
                    title={"Email Exists"}
                    onClose={() => {
                      setShowEmailModal(false);
                      setDisabledContinue(false);
                    }}
                    style={{ zIndex: 100054 }}
                  >
                    <div className="bzc-row">
                      <p>
                        {`An existing ${IsAffiliateFeatureEnabled ? "staff/affiliate" : "staff"
                          } member with the provided email address
        already exists. Please click "Continue" to create the client
        profile using the existing ${IsAffiliateFeatureEnabled ? "staff/affiliate" : "staff"
                          } member's details.`}
                      </p>
                      <div className="bzc-edit-session-options-row-buttons">
                        <Button
                          onClick={() => {
                            setShowEmailModal(false);
                            setUserData(undefined);
                            setDisabledContinue(false);
                            formRenderProps.onChange("UserId", {
                              value: undefined,
                            });
                          }}
                          className="ml-0"
                        >
                          Cancel
                        </Button>
                        <BuzopsButton
                          onClick={() => {
                            setShowEmailModal(false);
                            updateUserFields(formRenderProps);
                          }}
                          label={"Continue"}
                          className="mr-0"
                        />
                      </div>
                    </div>
                  </Dialog>
                )}

                {showMemberExistsModal && (
                  <Dialog
                    className="bz-selectClient-dialog bz-edit-email-dialog"
                    title={"Email Exists"}
                    onClose={() => {
                      setShowMemberExistsModal(false);
                      setDisabledContinue(false);
                    }}
                    style={{ zIndex: 100054 }}
                  >
                    <div className="bzc-row">
                      <p className="mb-1">
                        A client with the same email already exists.
                      </p>

                      <ul className="pl-4 mb-1">
                        <li>
                          {userData?.FirstName} {userData?.LastName} (
                          {userData?.Email})
                        </li>
                      </ul>

                      <p>Click "Continue" to proceed with this client.</p>

                      <div className="bzc-edit-session-options-row-buttons">
                        <Button
                          onClick={() => {
                            setShowMemberExistsModal(false);
                            setUserData(undefined);
                            setDisabledContinue(false);
                          }}
                          className="ml-0"
                        >
                          Cancel
                        </Button>
                        <BuzopsButton
                          onClick={() => {
                            setShowMemberExistsModal(false);
                            // props.isPaymentForm === false &&
                            props.fetchClientData(userData.UserMemberId);
                            // props.isPaymentForm === true &&
                            //   props?.updateSelectedClientData(userData);
                          }}
                          label={"Continue"}
                          className="mr-0"
                          autoFocus={true}
                        />
                      </div>
                    </div>
                  </Dialog>
                )}

                {showActivateModal && (
                  <Dialog
                    className="bz-selectClient-dialog bz-edit-email-dialog"
                    title={"Activate Prospect"}
                    onClose={() => {
                      setShowEmailModal(false);
                      setShowActivateModal(false);
                      setDisabledContinue(false);
                    }}
                    style={{ zIndex: 100054 }}
                  >
                    <div className="bzc-row">
                      <p className="mb-1">
                        This email ID and associated prospect is archived. Click
                        "Continue" to activate this archived prospect.
                      </p>
                      <div className="bzc-edit-session-options-row-buttons">
                        <Button
                          onClick={() => {
                            setShowActivateModal(false);
                            setDisabledContinue(false);
                            formRenderProps.onFormReset();
                          }}
                          className="ml-0"
                        >
                          Cancel
                        </Button>
                        <BuzopsButton
                          onClick={() => {
                            activateMember();
                            setShowEmailModal(false);
                            setDisabledContinue(false);
                            setShowPopup(false);
                            setSelectedValue("pick");
                          }}
                          label={"Continue"}
                          className="mr-0"
                          loading={btncontinueLoading}
                        />
                      </div>
                    </div>
                  </Dialog>
                )}
                <div>
                  {isStaff && (
                    <div
                      className="alert alert-warning mb-0 py-2 px-3"
                      role="alert"
                    >
                      {`This profile is already associated with a ${IsAffiliateFeatureEnabled ? "staff/affiliate" : "staff"
                        } in this club.
      Profile changes cannot be made. Changes can only be made from
      the ${IsAffiliateFeatureEnabled ? "staff/affiliate" : "staff"} page.`}
                    </div>
                  )}
                  <div className="addclient">
                    <div>
                      <Field
                        id={"FirstName"}
                        name={"FirstName"}
                        placeholder="First Name *"
                        label="First Name"
                        component={FormInput}
                        validator={(val: any) => NameValidator(val, "First Name")}
                        customvalidation={true}
                        hidelabel={true}
                        onChange={handleFieldChange}
                        disabled={isStaff}
                      />
                    </div>
                    <div>
                      <Field
                        id={"LastName"}
                        name={"LastName"}
                        placeholder="Last Name *"
                        label="Last Name"
                        component={FormInput}
                        validator={(val: any) => NameValidator(val, "Last Name")}
                        customvalidation={true}
                        hidelabel={true}
                        onChange={handleFieldChange}
                        disabled={isStaff}
                      />
                    </div>
                    <div>
                      <Field
                        id={"PhoneNumber"}
                        name={"PhoneNumber"}
                        placeholder="Phone Number *"
                        label="Phone Number"
                        component={FormMaskedTextBox}
                        mask="(999) 000-0000"
                        validator={mobileValidator}
                        customvalidation={true}
                        hidelabel={true}
                        onChange={handleFieldChange}
                        disabled={isStaff}
                      />
                    </div>
                    <div>
                      <Field
                        id={"Email"}
                        name={"Email"}
                        placeholder="Email Id *"
                        component={FormInput}
                        validator={(val: any) => emailValidator(val, "Email")}
                        hidelabel={true}
                        onChange={handleFieldChange}
                      />
                    </div>
                    <div>
                      <Field
                          name="Gender"
                          component={FormDropDownList}                         
                          data={GenderType} 
                          textField="text" 
                          dataItemKey="id" 
                          id="id" 
                          onChange={handleFieldChange}
                          customvalidation={true}
                          defaultItem={{ id: 0, text: "Please Select Gender" }}
                          placeholder={"Gender"}
                          hidelabel={true}
                          disabled={isDisableGender}
                          //validator={(val:any)=>GenderFormValidator(val?.id)}
                      />
                    </div>
                    {isgenderSpecifyOthers && (<div>
                      <Field
                        id={"GenderSpecifyOthers"}
                        name={"GenderSpecifyOthers"}
                        placeholder="Others *"
                        label="Others"
                        component={FormInput}
                        validator={(val: any) => GenderSpecifyOthersValidator(val, isgenderSpecifyOthers)}
                        customvalidation={true}
                        hidelabel={true}
                        onChange={(e) => handleFieldChange(e,formRenderProps)}
                      />
                    </div>)}
                  </div>
                  <div className="mt-3 mb-3 text-right">
                    <div className="">
                      <BuzopsButton
                        primary
                        type={"submit"}
                        label={"Add New Client"}
                        disabled={
                          !formRenderProps.allowSubmit ||
                          disabledContinue ||
                          props.clientSubmitBtnLoading
                        }
                        loading={props.clientSubmitBtnLoading}
                        key={props.clientSubmitBtnLoading?.toString()}
                      />
                    </div>
                  </div>
                </div>
              </FormElement>
            </div>
          )}
        />
      </div>
    );
  };


  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem, index } = itemProps;

    // Check if the current item is the button placeholder
    if (dataItem.isButton) {
      return (
        <li {...li.props} key={index} className="k-item">
          <Button
            primary={false}
            onClick={(e: any) => handleRadioClick(e)}
            className="btn-sm-addnewclient"
          >
            <i className="fas fa-user-plus"></i>
            Add New Client
          </Button>
        </li>
      );
    }

    const { FullName, Email, Phone } = dataItem || {};


    const itemChildren = React.createElement(
      'div',
      {},
      [
        React.createElement(
          'h5',
          { className: 'header-class' },
          `${FullName}`
        ),
        React.createElement(
          'small',
          { className: 'text-muted d-block ft-12' },
          `${Email}`
        ),
        React.createElement(
          'small',
          { className: 'text-muted d-block ft-12' },
          `${Phone ? `  ${Phone}` : ""}`
        )
      ]
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const CustomRadioButton: React.FC<CustomRadioButtonProps> = ({
    label,
    selectedValue,
    onChange,
  }) => {
    return (
      <div className="buzopsraido">
        <label className="form-check form-check-custom">
          <RadioButton
            disabled={props?.disabled}
            name="selectionType"
            value="pick"
            label="Pick a Client"
            checked={selectedValue === "pick"}
            onChange={() => setSelectedValue("pick")}
            className="form-check-input h-20px w-20px"
          />

          <div className="buzopsbox">
            <span className="form-check-label fw-bold">{label}</span>
          </div>
        </label>
      </div>
    );
  };
  const handleRadioClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPopupposition({
      x: rect.left + window.scrollX,
      y: rect.bottom + window.scrollY,
    });
    setShowPopup(true);
  };

  return (
    <>
      {!props?.checkout && showPopup && (

        <Dialog
          title={"Add New Client"}
          onClose={() => {
            setShowPopup(false);
            setisDisableGender(false);
          }}
          className="addnewclientdailog"
        >
          {clientCreate(props)}
        </Dialog>


      )}
      {selectedValue && selectedValue === "pick" && (
        <>
          <FormElement style={{ width: "100%" }}>
            <div className="">
              <div>
                {props?.checkout && <div>Client</div>}
                <div className="autocomplete-wrapper" style={{ display: 'flex', alignItems: 'center', width: props?.width ? props?.width : '100%' }}>

                  <AutoComplete
                    disabled={props?.disabled}
                    className="clientautocomplete mb-3"
                    data={modifiedData}
                    style={{ width: props?.width ? props?.width : '100%', minWidth: '300px' }}
                    itemRender={itemRender}

                    value={
                      props?.selectedClient?.type === "search"
                        ? props?.selectedClient?.value
                        : props?.selectedClient?.value?.FullName
                    }
                    onChange={(e: any) => props.handleClientSelecton(e)}
                    placeholder={"Search Client"}
                    loading={props?.clientSearchLoading}
                    listNoDataRender={() => (
                      <span className={"bzo-noData-found"}>
                        {props?.clientSearchLoading
                          ? "Searching for Client..."
                          : <Button onClick={(e: any) => handleRadioClick(e)} className="btn-sm">
                            Add Client
                          </Button>}
                      </span>
                    )}
                  />
                </div>
              </div>
            </div>
          </FormElement>
        </>
      )}
    </>
  );
};

export default AddClient;
