import {
  MdPhone,
  MdOutlineVideocam,
  MdOutlineLocationOn,
} from "react-icons/md";
export const sessions = [
  { id: 1, text: "Weekly" },
  { id: 2, text: "Monthly" },
  { id: 3, text: "Every 2 Months" },
  { id: 4, text: "Every 3 Months" },
  { id: 5, text: "Every 4 Months" },
  { id: 6, text: "Every 5 Months" },
  { id: 7, text: "Every 6 Months" },
  { id: 8, text: "Specific Days" },
];

export const dayPicker = [
  { id: 1, text: "First" },
  { id: 2, text: "Second" },
  { id: 3, text: "Third" },
  { id: 4, text: "Fourth" },
  { id: 5, text: "Last" },
];
export const weekdayPicker = [
  { id: 1, text: "Sunday" },
  { id: 2, text: "Monday" },
  { id: 3, text: "Tuesday" },
  { id: 4, text: "Wednesday" },
  { id: 5, text: "Thrusday" },
  { id: 6, text: "Friday" },
  { id: 7, text: "Saturday" },
];

export const weekDays = [
  { id: 1, text: "S" },
  { id: 2, text: "M" },
  { id: 3, text: "Tu" },
  { id: 4, text: "W" },
  { id: 5, text: "Th" },
  { id: 6, text: "F" },
  { id: 7, text: "Sa" },
];
export const recurrenceUnits = [
  { id: 4, text: "Months", value:'Month(s)' },
  { id: 2, text: "Days", value:'Day(s)' },
];

export const cardTypes = [
  { id: 1, text: "amex" },
  { id: 2, text: "dinersclub" },
  { id: 3, text: "discover" },
  { id: 4, text: "jcb" },
  { id: 5, text: "mastercard" },
  { id: 6, text: "visa" },
];

export const timeUnits = [
  { id: 1, text: "Hours" },
  { id: 2, text: "Minutes" },
  { id: 3, text: "Days" },
];

export const flatUnits = [
  { id: 1, text: "Per Group" },
  { id: 2, text: "Per Client" },
];
export const calendarColors = [
  "#008DB9",
  "#7239ea",
  "#0ab3cc",
  "#2f7d20",
  "#a21616",
  "#0ab3ac",
  "#2f7d96",
];

export const Type = [
  { id: 0, text: "Select Type" },
  { id: 1, text: "Paid" },
  { id: 3, text: "Complimentary" },
];

export const ParentClassStatusradio = [
  { value: 1, label: "Active" },
  { value: 2, label: "In-Active" },
];

export const Typeradio = [
  { value: 1, label: "Paid" },
  { value: 3, label: "Non Paid" },
];
export const EventType = [
  { value: 1, label: "Recurring" },
  { value: 2, label: "OneTime" },
];

export const PaymentOptionsradio = [
  { value: 1, label: "Card on File Required (Payment Required)" },
  { value: 2, label: "Card on File Required (No Payment Required)" },
  { value: 3, label: "Card on File Not Required" },
];

export const SessionTypeInfo = [
  { id: 1, text: "Limited" },
  { id: 2, text: "Unlimited" },
  { id:3,text: "Pre-set"}
];
export const PackageFrequency = [
  { text: "One Time", id: 1, description: "One Time", shortValue: "" },
  { text: "Daily", id: 2, description: "Daily", shortValue: "day" },
  { text: "Monthly", id: 4, description: "Monthly", shortValue: "month" },
  { text: "Yearly", id: 5, description: "Yearly", shortValue: "year" },
];
export const ClassStatus = [
  { id: 1, text: "active" },
  { id: 2, text: "show" },
  { id: 3, text: "noshow" },
  { id: 4, text: "dispute" },
  { id: 5, text: "retired" },
];

export const PackageStatus = [
  { id: 0, text: "Total" },
  { id: 1, text: "Active" },
  { id: 2, text: "InActive" },
  { id: 3, text: "Expired" },
  { id: 4, text: "Cancelled" },
];

export const GenderType = [
  {id:1,text:"Male"},
  {id:2,text:"Female"},
  {id:3,text:"Prefer not to say"},
  {id:4,text:"Other"},
]

export enum GenderTypeEnum {
  "Male"=1,
  "Female"=2,
  "PreferNotToSay"=3,
  "Others"=4,
}

export const CalendarItems = [
  { text: "All", id: 1 },
  { text: "Future", id: 2 },
  { text: "Past", id: 3 },
  { text: "Next 7 days", id: 4 },
  { text: "Next 30 days", id: 5 },
  { text: "Last 30 days", id: 6 },
  { text: "Last 7 days", id: 7 },
  { text: "Last 3 months", id: 8 },
  { text: "Last 6 months", id: 9 },
  { text: "This year", id: 10 },
  { text: "Last year", id: 11 },
];
export const ParentClassStatus = [
  { id: 1, text: "Active" },
  { id: 2, text: "Retired" },
];

export const AuditType = [
  { text: "None", id: 0, name: "None" },
  { text: "Membership", id: 2, name: "plan" },
  { text: "Group Training", id: 4, name: "GroupTraining" },
  { text: "One-on-One", id: 8, name: "service" },
  { text: "Add-On", id: 16, name: "addon" },
  { text: "Onsite Access", id: 32, name: "OnsiteAccess" },
  { text: "One Time Charge", id: 64, name: "OneTimeCharge" },
  { text: "Payment Profile", id: 128, name: "PaymentProfile" },
  { text: "Group", id: 256, name: "GroupServices" },
  { text: "Packages", id: 512, name: "package" },
];

export const PaymentOptions = [
  { id: 0, text: "Select Payment Option" },
  { id: 1, text: "Card on File Required (Payment Required)" },
  { id: 2, text: "Card on File Required (No Payment Required)" },
  { id: 3, text: "Card on File Not Required" },
];

export const CancellationOnOptions = [
  { id: 1, text: "Today" },
  { id: 2, text: "Cancel without 30-day notice" },
  { id: 3, text: "Cancel with 30-day notice" },
];

export const UnFreezeChargeTypes = [
  { id: 0, text: "ChargeForProration" },
  { id: 1, text: "NoChargeForProration" },
];
export const RenewalExpirationTypes = [
  { id: 11, text: "Refund" },
  { id: 10, text: "No-Refund" },
];

export const UnfreezeRecurrenceOption = [
  { text: "Please select", id: 0 },
  { text: "Weekly", id: 1 },
  { text: "By Weekly", id: 2 },
  { text: "Monthly", id: 3 },
];

export const allTimeZones: any = {
  "America/Anchorage": "Alaskan Standard Time",
  "America/Chicago": "Central Standard Time",
  "America/New_York": "Eastern Standard Time",
  "Pacific/Honolulu": "Hawaiian Standard Time",
  "Asia/Calcutta": "India Standard Time",
  "America/Denver": "Mountain Standard Time",
  "America/Los_Angeles": "Pacific Standard Time",
};
export const timeZoneLongConvertion: any = {
  "Alaskan Standard Time": "Alaskan Standard Time",
  "Central Standard Time": "Central Standard Time",
  "Eastern Standard Time": "Eastern Standard Time",
  "Hawaiian Standard Time": "Hawaiian Standard Time",
  "India Standard Time": "India Standard Time",
  "Mountain Standard Time": "Mountain Standard Time",
  "Pacific Standard Time": "Pacific Standard Time",
  "Alaska Daylight Time": "Alaskan Standard Time",
  "Central Daylight Time": "Central Standard Time",
  "Eastern Daylight Time": "Eastern Standard Time",
  "Hawaii-Aleutian Standard Time": "Hawaiian Standard Time",
  "Mountain Daylight Time": "Mountain Standard Time",
  "Pacific Daylight Time": "Pacific Standard Time",
};
export const timeZoneLongShort: any = {
  AKST: "AKST",
  CST: "CST",
  EST: "EST",
  HST: "HST",
  IST: "IST",
  MST: "MST",
  PST: "PST",
  ADT: "AKST",
  CDT: "CST",
  EDT: "EST",
  HAST: "HST",
  MDT: "MST",
  PDT: "PST",
};

export const allTimeZonesArray: any = [
  {
    key: "America/Anchorage",
    name: "Alaskan Standard Time",
    abbr: "AKST",
    UTC: -8,
  },
  {
    key: "America/Chicago",
    name: "Central Standard Time",
    abbr: "CST",
    UTC: -5,
  },
  {
    key: "America/New_York",
    name: "Eastern Standard Time",
    abbr: "EST",
    UTC: -4,
  },
  {
    key: "Pacific/Honolulu",
    name: "Hawaiian Standard Time",
    abbr: "HST",
    UTC: -10,
  },
  {
    key: "Asia/Calcutta",
    name: "India Standard Time",
    abbr: "IST",
    UTC: +5.5,
  },
  {
    key: "America/Denver",
    name: "Mountain Standard Time",
    abbr: "MST",
    UTC: -6,
  },
  {
    key: "America/Los_Angeles",
    name: "Pacific Standard Time",
    abbr: "PST",
    UTC: -7,
  },
];

export const CARD_TYPES: any = {
  mastercard: "MASTERCARD",
  visa: "VISA",
  amex: "AMERICAN_EXPRESS",
  jcb: "JCB",
  dinersclub: "DINERS",
  discover: "DISCOVER",
};

export const MemberType = [
  { id: 1, text: "Family Member" },
  { id: 2, text: "Child" },
];

export const UsermemberStatusEnum = {
  None: 0,
  Active: 1,
  Inactive: 2,
  ActionRequired: 4,
  AllowService: 8,
};

export const PaymentMethodItems = [
  {
    iconClass: "fa fa-credit-card pr-1",
    text: "Add Credit Card",
    key: "addCard",
  },
  {
    iconClass: "fa fa-university pr-1",
    text: "Add Bank Account",
    key: "addBank",
  },
];

export const verbiageMappings: any = {
  plan: "Memberships",
  service: "One-on-One",
  addon: "AddOn",
  package: "Package",
};

export const locationOptions = [
  {
    id: 2,
    value: "VirtualMeeting",
    text: "Virtual Meeting",
    subText: "Virtual Meeting",
    icon: <MdOutlineVideocam className={"bz-md-video"} />,
  },
  {
    id: 4,
    value: "PhoneCall",
    text: "Phone Call",
    subText: "Enter attendee number",
    icon: <MdPhone className={"bz-md-phone"} />,
  },
  {
    id: 8,
    value: "InPersonMeeting",
    text: "In-person Meeting",
    subText: "Enter location details",
    icon: <MdOutlineLocationOn className={"bz-md-location"} />,
  },
];

export const ScheduleModeOptionEnum = {
  None: 0,
  PhoneCallIWillCall: 1, //client
  PhoneCallMyInviteeShouldCall: 2, //Trainee
  InPersonMeetingBusinessAddress: 3,
  InPersonMeetingOtherAddres: 4,
  VirtualMeetingBuzopsMeeting: 5,
  VirtualMeetingOtherMeeting: 6,
};
export const PackageInstanceStatus = [
  { id: 0, text: "None" },
  { id: 1, text: "Active" },
  { id: 2, text: "In-Active" },
  { id: 3, text: "Expired" },
  { id: 4, text: "Cancelled" },
  { id: 5, text: "Future-Not Paid" },
  { id: 6, text: "Future-Paid" },
  { id: 7, text: "Completed" },
  { id: 8, text: "Cancel-in-Progress" },
];

export enum checkinTypeEnum {
  "Location" = 0,
  "Group" = 2,
  "OneOnOne" = 3,
}

export enum checkinTypeStringEnum {
  "Location" = "Location",
  "Group" = "Group",
  "Appointments" = "Appointments",
}
export enum checkinModeEnum {
  "From Access Control System" = 0,
  "By Staff" = 1,
  "From Kiosk By User " = 2,
  "Online By User" = 3,
}

export const FilterTypeForService: any = {
  16: 2, //add-on service,
  2: 1, //membership/plan
  512: 512, // package
  8: 0, //one-on-one
  256: 0, //one-on-one
};
export const CustomFieldsTypeOptions = [
  {
    Id: 0,
    Name: "String",
  },
  {
    Id: 1,
    Name: "Numeric",
  },
  {
    Id: 5,
    Name: "MultilineText",
  },
  {
    Id: 7,
    Name: "PhoneNumber",
  },
];

export const CustomFieldsTypeEnum = {
  String: 0,
  Numeric: 1,
  Checkbox: 2,
  Dropdown: 3,
  MutilSelectDropdown: 4,
  MultilineText: 5,
  RadioButton: 6,
  PhoneNumber: 7,
};
export const CouponDurationType: any = {
  1: "At Checkout",
  2: "NeverEnding",
  3: "Limited",
};

export const MemberCouponStatusEnum = {
  None: 0,
  Active: 1,
  Completed: 2,
  DeActivated: 3,
};

export const MemberCouponStatus = [
  {
    Id: 0,
    Name: "None",
  },
  {
    Id: 1,
    Name: "Active",
  },
  {
    Id: 2,
    Name: "Completed",
  },
  {
    Id: 3,
    Name: "DeActivated",
  },
];
export const ClubPlanStatus = [
  { id: 0, text: "None" },
  { id: 1, text: "Active" },
  { id: 2, text: "Trial" },
  { id: 3, text: "PaymentPending" },
  { id: 5, text: "ChangePlanRequested" },
  { id: 6, text: "ChangePlanScheduled" },
  { id: 7, text: "PlanChanged" },
];

export const ClubPlanStatusEnum = {
  None: 0,
  Active: 1,
  Trial: 2,
  PaymentPending: 3,
  ChangePlanRequested: 5,
  ChangePlanScheduled: 6,
  PlanChanged: 7,
};

export const PaymentTransactionStatus = [
  { id: 0, text: "Initialized",description:"Initialized" },
  { id: 4, text: "TransactionSuccessfulPaymentProcessedSuccessfully",description:"Transaction completed successfully" },
  { id: 5, text: "TransactionRefundedPaymentrefundedfailed",description:"Transaction Refund Failed" },
  { id: 6, text: "TransactionRefundedPaymentrefundsuccessfully",description:"Transaction Refunded successfully" },
  { id: 7, text: "TransactionFailedPaymentFailed",description:"Transaction Failed" },
  { id: 9, text: "TransactionSuccessfulPaymentWaivedSuccessfully",description:"Transaction Waived Successfully" },
  { id: 10, text: "TransactionYetToRefund",description:"Transaction Yet To Refund" },
  { id: 11, text: "TransactionVoided",description:"Transaction Voided" },
  { id: 12, text: "TransactionReturned",description:"Transaction Returned" },
  { id: 13, text: "TransactionChargeback",description:"Transaction Charge back" },
];

export const RewardCommissionType = [
  { id: 1, text: "Fixed Amount" },
  { id: 2, text: "Percentage of sales" },
];
export const SecondTierCommissionType = [
  { id: 1, text: "Override (as % from sale amount)" },
  { id: 2, text: "Net Override (as % from sale amount minus commission)" },
];

export const RewardTypeList = [
  { id: 1, key: 1, text: "Payout", value: "Payout" },
  { id: 3, key: 3, text: "Tier-2 Commission", value: "Tier2Commission" },
  // { id: 3, key: 3, text: "SecondCommissions", value: "Second Commissions" },
];
export const RewardTypeEnum = {
  "Payout":1,
  "Bonus":2,
  "SecondCommissions":3,
}

export const OfferDurationType=[
  { id: 1, text: "One Time" },
  { id: 2, text: "Never Ending" },
  { id: 3, text: "Limited Duration" },
]
export const OfferType=[
  { id: 1, text: "Fixed" },
  { id: 2, text: "Percentage" },
]
export const OfferTypeEnum:any = {
  0:"None",
  1:"Fixed",
  2:"Percentage",
};
export const OfferDurationTypeEnum:any = {
  0:"None",
  1:"One Time",
  2:"Never Ending",
  3:"Limited Duration",
};

export const UserAffiliateStatus = [
  { id: 0, text: "In-Active" },
  { id: 1, text: "Active" },
  { id: 2, text: "Invited" },
  { id: 3, text: "Declined" },
];


export const enum AffiliateDashboardLinks {
  OVERVIEW = "overview",
  PROMOTIONS = "promotions",
  PAYOUTS = "payouts",
  MYLINKS = "mylinks",
  MYPROFILE = "myprofile",
  PROMOTIONACTIVITY = "promotionactivity",
}

export enum LinksTab {
  APPOINTMENT = "Appointment",
  PACKAGE = "Package",
  MEMBERSHIP ="Membership"
}

export const PromotionSubscriptionTypes = [
  { text: "Memberships", id: 1 },
  { text: "Appointments", id: 3},
  { text: "Packages", id: 5 },
];
export enum PromotionSubscriptionTypesENUM {
  MEMBERSHIP =1,
  APPOINTMENT = 3,
  PACKAGE = 5,
}

export const BankAccountTypes = [
  { id: 0, text: "Checking" },
  { id: 1, text: "Savings" },
];

export enum UserAffiliateStatusEnum {
  InActive=0,
  Active=1,
  Invited=2,
  Declined=3,
}

export enum AssociateTypeEnum {
  OnlyStaff=1,
  OnlyAffiliate=2,
  StaffAndAffiliate=3
}

export enum PackageFrequencyEnum {
  "ONETIME" = 1,
  "DAILY" = 2,
  "MONTHLY" = 4,
  "YEARLY" = 5,
}

export enum ApplicationTypeEnum {
  "OWNER" = 1,
  "STAFF" = 8,
  "AFFILIATE" = 4,
  "CLIENT" = 2,
}
export enum CouponDurationTypeEnum {
  "ONETIME" = 1,
  "NEVERENDING" = 2,
  "LIMITED" = 3,
}

export const PackageInstanceClassMappingStatus = [
  { id: 0, text: "None" },
  { id: 1, text: "Active" },
  { id: 2, text: "Completed" },  
  { id: 3, text: "Payment Not Processed" },  
  { id: 4, text: "Payment Not Processed Expired" },  
  { id: 5, text: "Skiped" },  
  { id: 6, text: "In-Active" },
  { id: 7, text: "Future" }  
];

export enum PackageInstanceStatusEnum {
  "NONE" = 0,
  "ACTIVE" = 1,
  "INACTIVE" = 2,
  "EXPIRED" = 3,
  "CANCELLED" = 4,
  "FUTURENOTPAID" = 5,
  "FUTUREPAID" = 6,
  "COMPLETED" = 7,
  "FUTURECANCEL" = 8,
}

export const PackageCancellationOnOptions = [
  { id: 1, text: "Today" },
  { id: 2, text: "Current Billing Cycle" },
];

export const CommunicationPreferenceList = [
  { id: 2, text: "Email" },
  { id: 4, text: "SMS" },
  { id: 8, text: "Email & SMS" },
];

export const CommunicationPreferenceOnlyEmail = [
  { id: 2, text: "Email" },
];

export enum CommunicationPreferenceEnum {
  Email=2,
  Text=4,
  Both =8
};

export const CreditsErrorMessage ="Insufficient credits. Please add credits to complete your purchase"

export const PaymentMadeForItems = [
  { text: "None", id: 0, name: "None",IconClass:"" },
  { text: "Membership", id: 1, name: "plan",IconClass:"fas fa-id-card-alt" },
  { text: "Club Service", id: 2, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Membership Cancellation", id: 3, name: "plan",IconClass:"fas fa-id-card-alt" },
  { text: "Membership Freeze", id: 4, name: "plan",IconClass:"fas fa-id-card-alt" },
  { text: "One-on-One", id: 5, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Penalty", id: 6, name: "penalty",IconClass:"" },
  { text: "Club Service Freeze", id: 7, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Club Service Cancellation", id: 9, name: "service",IconClass:"fas fa-user-friends" },
  { text: "One-on-One Cancellation", id: 10, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Membership UnFreeze", id: 11, name: "plan",IconClass:"fas fa-id-card-alt" },
  { text: "Club Service UnFreeze", id: 12, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Club Service Adjustment", id: 14, name: "service",IconClass:"fas fa-user-friends" },
  { text: "Add-On", id: 16, name: "addon",IconClass:"" },
  { text: "Add-On Cancellation", id: 17, name: "addon",IconClass:"" },
  { text: "One Time Charge", id: 18, name: "OneTimeCharge",IconClass:"fas fa-bolt" },
  { text: "Add-On Freeze", id: 19, name: "addon",IconClass:"" },
  { text: "Add-On Unfreeze", id: 20, name: "addon",IconClass:"" },
  { text: "Add-On Withdraw", id: 21, name: "addon",IconClass:"" },
  { text: "Club Subscription Payment", id: 22, name: "clubsubscription",IconClass:"" },
  { text: "Group", id: 23, name: "GroupServices",IconClass:"fa fa-users" },
  { text: "Packages", id: 24, name: "package",IconClass:"fas fa-box-open" },
  { text: "Group Cancellation", id: 25, name: "GroupServices",IconClass:"fa fa-users" },
  { text: "Package Cancellation", id: 26, name: "package",IconClass:"fas fa-box-open" },
  { text: "POS", id: 27, name: "PosPurchase",IconClass:"" },
  { text: "Credit", id: 28, name: "Credit",IconClass:"Credit" },
];

export const ExpiryOnItems = [
  { id: 2, text: "Day(s)" },
  { id: 4, text: "Month(s)" },
];
export const endafterItems = [
  {
    label: "Never",
    value: 0,
  },
  {
    label: "No of occurrence",
    value: 1,
  },
];
export const ExpiryRadioItems = [
  {
    label: "Expire After",
    value: 0,
  },
  {
    label: "Expire On",
    value: 8,
  },
];

export const PromotionPayoutStatusType = [
  { id: 0, text: "Submitted" },
  { id: 1, text: "Paid" },
  { id: 2, text: "Rejected" }, 
];

export const PaymentMode = [
  { id: 1, text: "CHARGE" },
  { id: 7, text: "RETURN" },
  { id: 10, text: "CHARGEBACK" },  
  { id: 9, text: "REFUND" },  
  { id: 13, text: "VOIDED" }  
];
export const PaymentExecutionMode = [
  { id: 1, text: "Down Payment" },
  { id: 2, text: "Recurrence" },
  { id: 3, text: "Manual Posting" },  
];

export enum SourceTransactionPaymentModeEnum {
  "None" = 0,
  "CHARGE" = 1,
  "M_CHECK" = 2,
  "CHECK_REFUND" = 3,
  "CC_CHECK" = 4,
  "CC_CREDIT" = 5,
  "CC_CASH" = 6,
  "RETURN" = 7,
  "CC_REFUND" = 8,
  "REFUND" = 9,
  "CHARGEBACK" = 10,
  "WAIVE" = 11,
  "EXPIRED" = 12,
  "VOIDED" = 13,
  "COMPLIMENTARTY" = 14,
}

export const PromoPayoutDisplayStatus = [
  { id: -1, text: "All (Pending, Paid, Rejected, Overdue)" },
  { id: 0, text: "Pending" },
  { id: 1, text: "Paid" },
  { id: 2, text: "Rejected" },
  { id: 4, text: "Overdue" },
];

export enum PromoPayoutDisplayStatusEnum {
  "PENDING" = 0,
  "PAID" = 1,
  "REJECTED" = 2,
  "OVERDUE" = 4,
}

export const PrivateServiceTypeEnum = {
  "PrivatePaid": "Paid",
  "PrivateUnpaid": "Consultation",
  "SemiPrivatePaid": "SemiPrivatePaid",
  "SemiPrivateUnpaid": "SemiPrivateUnpaid"
};

export enum PayoutGeneratedFromEnum {
  None = 0,
  ServiceRatePayout = 1,
  OneOnOnePayout = 2, 
  ProviderPayout = 3,
  ClassPayout = 4,
}

export const PackagePresetFrequency = [
  { text: "Daily", id: 2, description: "Daily", shortValue: "day" },
  { text: "Monthly", id: 4, description: "Monthly", shortValue: "month" },
];

export enum FormItemTypes {
  CARD ='card',
  BOX = 'box'
}

export const FormTemplateStatus = [
  { id: 0, text: "All" },
  { id: 1, text: "Draft" },
  { id: 2, text: "Published" },
  { id: 4, text: "In-Active" },
];
export const FormSortList = [
  { id: "FormName", text: "Form Name" },
  { id: "FormCategory", text: "Category" },
  { id: "UpdateDateTime", text: "Updated on" },
];
export const FormTemplateCategory = [
  { id: 1, text: "General",displayName:'Customer Form' },
  { id: 2, text: "Referral",displayName:'Lead Form' },
  // { id: 4, text: "Survey" },
  // { id: 8, text: "Agreement" },
];
export enum FormTemplateStatusEnum  {
  DRAFT = 'Draft',
  PUBLISHED = 'Published', 
  INACTIVE = 'Inactive'
}
export enum FormTemplateCategoryEnum  {
  GENERAL = 'General',
  REFERRAL = 'Referral', 
  SURVEY = 'Survey',
  AGREEMENT = 'Agreement'
}
export enum FormTemplateStatusIntEnum  {
  DRAFT = 1,
  PUBLISHED = 2, 
  INACTIVE = 4
}

export enum FormTemplateCategoryIntEnum  {
  GENERAL = 1,
  REFERRAL = 2
}
export enum MemberTypeEnum {
  PRIMARY=0,
  SUBMEMBER=1,
  CHILD=2
}
export enum SessionTypeEnum {
  LIMITED = 1,
  UNLIMITED = 2,
  PRESET = 3,
}

export enum PresetPackageInstanceRenewalStatus{
  OFF = 0,
  ON = 1,
  READYFORRENEW =2,
  RENEWED=3
}

export enum TypeEnum {
  Class = 1,
  PrivateAppointment = 2,
  SemiPrivateAppointment = 4,
}

export const RenewOptionsNoLastPayment = [
  {
    id: 0,
    text: "None",
  },
  {
    id: 2,
    text: "Renew after Last Appointment Completion",
  },
];

export const FileTypes=[
  {
    value: "image, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    label: "Accept all file types",
  },
  { value: "image", label: "Image (accept: .jpg, .jpeg, .png)" },
  { value: "application/pdf", label: "PDF (accept: .pdf)" },
  {
    value: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    label: "Word (accept: doc, docx)",
  },
  {
    value: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    label: "Excel (accept: xls, xlsx, csv)",
  },
]
export type ScheduleAppointmentkeys = "Appointment" | "Provider" | "AddPackage" | "PurchasePackage" | "Package" | "ShowPurchasedPackages"


export const TierBasedRewardTypes = [
  { id: 5, text: "Active Payed Clients" },
  { id: 6, text: "Sales" },
];