// eslint-disable-next-line max-classes-per-file
import fetch from 'isomorphic-fetch';
import { saveAs } from 'file-saver';
import React, { useRef, useState } from 'react';

import myxss from './myxss';
import ComponentHeader from './ComponentHeader';
import ComponentLabel from './ComponentLabel';
import DatePicker from './DatePicker';
import { useApiClient } from 'services/axios-service-utils';
import withNotification from 'components/Hoc/withNotification';
import { FileTypes } from 'utils/form-utils';
import SignaturePad from 'components/signaturePad';
import bzImages from '../../../Images';
import { Loader } from '@progress/kendo-react-indicators';

const BaseFormELements = {};

class Header extends React.Component {
  render() {
    // const headerClasses = `dynamic-input ${this.props.data.element}-input`;
    let classNames = 'static';
    if (this.props.data.bold) { classNames += ' bold'; }
    if (this.props.data.italic) { classNames += ' italic'; }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <h3 className={classNames} dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }} />
      </div>
    );
  }
}

class Paragraph extends React.Component {
  render() {
    let classNames = 'static pt-1';
    if (this.props.data.bold) { classNames += ' bold'; }
    if (this.props.data.italic) { classNames += ' italic'; }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }
    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <p className={classNames} dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }} />
      </div>
    );
  }
}

class Label extends React.Component {
  render() {
    let classNames = 'static';
    if (this.props.data.bold) { classNames += ' bold'; }
    if (this.props.data.italic) { classNames += ' italic'; }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <label className={`${classNames} form-label`} dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }} />
      </div>
    );
  }
}

class LineBreak extends React.Component {
  render() {
    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <hr />
      </div>
    );
  }
}

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.type = 'text';
    props.className = 'form-control';
    props.name = this.props.data.field_name;
    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <input {...props} />
        </div>
      </div>
    );
  }
}

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.type = 'text';
    props.className = 'form-control';
    props.name = this.props.data.field_name;
    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <input {...props} />
        </div>
      </div>
    );
  }
}

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = {
      phoneNumber: this.props.defaultValue || '',
      isFocused: false,
    };
  }

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  handleChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, '');
    console.log("value   ", value.length)
    if (value.length <= 10) {
      if (this.state.phoneNumber.length > event.target.value.length) {
        if (value.length <= 6) {
          value = value.slice(0, -1);
        }
      }
      value = value ? value.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3') : '';
    } else {
      value = value.substring(0, 14);
    }
    this.setState({ phoneNumber: value });
    this.inputField.current.value = value;
  };

  render() {
    const props = {};
    props.type = 'tel';
    props.className = 'form-control';
    props.name = this.props.data.field_name;
    if (this.state.isFocused) {
      props.placeholder = "(___) ___-____";
    }
    props.maxLength = 14;
    props.value = this.state.phoneNumber;
    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <input
            {...props}
            onChange={(e: any) => this.handleChange(e)}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
        </div>
      </div>
    );
  }
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    // console.log(props,'ppppppppppppppp')
    // if (props.data.text === "ZipCode") {
    //   props.data.maxLength = 5;
    // }
  }

  handleChange = (event) => {
    let { value } = event.target;

    if (this.props.data?.maxLength) {
      if (value.length > this.props.data?.maxLength) {
        value = value.slice(0, this.props.data?.maxLength);
      }
    }

    if (value < 0) {
      value = Math.abs(value);
      this.inputField.current.value = value;
    } else {
      this.inputField.current.value = value;
    }
  };

  render() {
    const props = {};
    props.type = "number";
    props.min = 0;
    if (this.props.data.maxLength) {
      props.maxLength = this.props.data.maxLength;
    }
    props.className = "form-control";
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    if (this.props.read_only) {
      props.disabled = "disabled";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <input {...props} onChange={this.handleChange} />
        </div>
      </div>
    );
  }
}

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <textarea {...props} />
        </div>
      </div>
    );
  }
}

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.className = 'form-control';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    if (this.props.read_only) {
      props.disabled = 'disabled';
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div className='selectcontainer'>
            <select {...props}>
              {this.props.data.options?.length > 0 && <option value="" key="select_option">Select</option>}
              {this.props.data.options?.length > 0 &&
                this.props.data.options.map((option) => {
                  const this_key = `preview_${option.key}`;
                  return (
                    <option value={option.value} key={this_key}>
                      {option.text}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    );
  }
}

class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: props.defaultValue,
      visibleSignPad: false,
      signature: "",
      signatureText: "",
      signatureFont: "",
      signatureType: ""
    };
    this.inputField = React.createRef();
    this.canvas = React.createRef();
    this.clear = this.clear.bind(this);
  }

  clear = () => {
    this.setState({
      defaultValue: '',
      visibleSignPad: false,
      signature: "",
      signatureText: "",
      signatureFont: "",
      signatureType: ""
    });
    this.canvas.current = "";
    //  this.canvas.current.clear();
    console.log("this.canvas.current ", this.canvas.current);
  }
  //to saving signature
  generatedImage = (imageString, text = "", font = "", Type = "") => {
    this.canvas.current = imageString;
    this.setState({
      isFormChanged: true,
      signature: imageString,
      signatureText: text,
      signatureFont: font,
      signatureType: Type
    });
  };
  handleSignaturePad = () => {
    this.setState({
      visibleSignPad: true
    });
  }


  render() {
    const { defaultValue } = this.state;
    let canClear = !!defaultValue;
    const props = {};
    props.type = 'hidden';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = defaultValue;
      props.ref = this.inputField;
    }
    const pad_props = {};
    // umd requires canvasProps={{ width: 400, height: 150 }}
    if (this.props.mutable) {
      pad_props.defaultValue = defaultValue;
      pad_props.ref = this.canvas;
      canClear = !this.props.read_only;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    let sourceDataURL;
    if (defaultValue && defaultValue.length > 0) {
      sourceDataURL = `data:image/png;base64,${defaultValue}`;
    }
    const containerStyle = {
      width: '100%', // Full width of the container
      height: '100px', // Full height of the viewport
      position: 'relative', // To ensure the img can be absolutely positioned within this div
      overflow: 'hidden', // To clip the image if necessary
      margin: '10px 0',
      border: '1px solid #ced4da',
      borderRadius: '10px',
      marginTop: '0'
    };

    const imageStyle = {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '200px',
      height: '100px'
    };

    return (
      <div style={{ ...this.props.style, minHeight: "100px" }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {this.props.read_only === true || !!sourceDataURL
            ? (<img src={sourceDataURL} style={{ minwidth: "100px", minheight: "100px" }} />)
            : (

              <div
                style={containerStyle}
                onClick={() => {
                  this.handleSignaturePad();
                }}
              // style={{ display: "flex", justifyContent: "center" }}
              >
                {this.state.signature !== "" ? (
                  this.state.signatureText && this.state.signatureFont ? (
                    <span
                      className="text-signature"
                      style={{
                        fontFamily: this.state.signatureFont,
                        alignSelf: "center",
                        fontSize:
                          this.state?.signatureType === "type" ? `25px` : `36px`,
                        overflow: "visible",
                        padding:
                          this.state?.signatureType === "type" ? `2px` : `0px`,
                      }}
                    >
                      {this.state.signatureText}
                    </span>
                  ) : (
                    <img
                      src={this.state.signature}
                      placeholder={"Please Sign"}
                      onClick={() => {
                        this.handleSignaturePad();
                      }}
                      style={imageStyle}
                    ></img>
                  )
                ) : (
                  <div>
                    <img style={imageStyle} src={bzImages.SignHereImg} className='signform' />
                  </div>
                )}
              </div>
            )
            //(<SignaturePad {...pad_props} canvasProps={{width: 550, height: 250, className: 'sigCanvas'}}   />)
          }
          {this.state.visibleSignPad &&
            <SignaturePad
              staffPage={false}
              generatedImage={(imageString, text, font, typeVal) =>
                this.generatedImage(imageString, text, font, typeVal)
              }
              key={"sign"}
              onClose={() => this.setState({
                visibleSignPad: false
              })}
            />}

          {canClear && (
            <button type='button' className="btn btn-link btn-xs" onClick={() => this.clear()} title="Clear Signature">Clear Signature</button>)}
          <input {...props} />
        </div>
      </div>
    );
  }
}

class Checkboxes extends React.Component {
  constructor(props) {
    super(props);
    this.options = {};
  }

  render() {
    const self = this;
    let classNames = 'custom-control custom-checkbox';
    if (this.props.data.inline) { classNames += ' option-inline'; }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div className='formradioresposive'>
            {this.props.data.options.map((option) => {
              const this_key = `preview_${option.key}`;
              const props = {};
              props.name = `option_${option.key}`;

              props.type = 'checkbox';
              props.value = option.value;
              if (self.props.mutable) {
                props.defaultChecked = self.props.defaultValue !== undefined && self.props.defaultValue.indexOf(option.key) > -1;
              }
              if (this.props.read_only) {
                props.disabled = 'disabled';
              }
              return (
                <div className={classNames} key={this_key}>
                  <input id={`fid_${this_key}`} className="custom-control-input" ref={c => {
                    if (c && self.props.mutable) {
                      self.options[`child_ref_${option.key}`] = c;
                    }
                  }} {...props} />
                  <label className="custom-control-label" htmlFor={`fid_${this_key}`}>{option.text}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.options = {};
  }

  render() {
    const self = this;
    let classNames = 'custom-control custom-radio';
    if (this.props.data.inline) { classNames += ' option-inline'; }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    return (
      <div style={{ ...this.props.style }} className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div className='formradioresposive'>
            {this.props.data.options.map((option) => {
              const this_key = `preview_${option.key}`;
              const props = {};
              props.name = self.props.data.field_name;

              props.type = 'radio';
              props.value = option.value;
              if (self.props.mutable) {
                props.defaultChecked = (self.props.defaultValue !== undefined &&
                  (self.props.defaultValue.indexOf(option.key) > -1 || self.props.defaultValue.indexOf(option.value) > -1));
              }
              if (this.props.read_only) {
                props.disabled = 'disabled';
              }

              return (
                <div className={classNames} key={this_key}>
                  <input id={`fid_${this_key}`} className="custom-control-input" ref={c => {
                    if (c && self.props.mutable) {
                      self.options[`child_ref_${option.key}`] = c;
                    }
                  }} {...props} />
                  <label className="custom-control-label" htmlFor={`fid_${this_key}`}>{option.text}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// class FileUpload extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { fileUpload: null };
//   }

//   displayFileUpload = (e) => {
//     const self = this;
//     const target = e.target;
//     let file;

//     if (target.files && target.files.length > 0) {
//       file = target.files[0];

//       self.setState({
//         fileUpload: file,
//       });
//     }
//   };

//   clearFileUpload = () => {
//     this.setState({
//       fileUpload: null,
//     });
//   };

//   saveFile = async (e) => {
//     e.preventDefault();
//     const sourceUrl = this.props.defaultValue;
//     const response = await fetch(sourceUrl, {
//       method: 'GET',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json; charset=utf-8',
//       },
//       responseType: 'blob',
//     });
//     const dispositionHeader = response.headers.get('Content-Disposition');
//     const resBlob = await response.blob();
//     // eslint-disable-next-line no-undef
//     const blob = new Blob([resBlob], {
//       type: this.props.data.fileType || response.headers.get('Content-Type'),
//     });
//     if (dispositionHeader && dispositionHeader.indexOf(';filename=') > -1) {
//       const fileName = dispositionHeader.split(';filename=')[1];
//       saveAs(blob, fileName);
//     } else {
//       const fileName = sourceUrl.substring(sourceUrl.lastIndexOf('/') + 1);
//       saveAs(response.url, fileName);
//     }
//   };

//   render() {
//     let baseClasses = 'SortableItem rfb-item';
//     const name = this.props.data.field_name;
//     const fileInputStyle = this.state.fileUpload ? { display: 'none' } : null;
//     if (this.props.data.pageBreakBefore) {
//       baseClasses += ' alwaysbreak';
//     }
//     return (
//       <div style={{ ...this.props.style }} className={baseClasses}>
//         <ComponentHeader {...this.props} />
//         <div className="form-group">
//           <ComponentLabel {...this.props} />
//           {this.props.read_only === true &&
//           this.props.defaultValue &&
//           this.props.defaultValue.length > 0 ? (
//             <div>
//               <button
//                 className='btn btn-default'
//                 onClick={this.saveFile}
//               >
//                 <i className='fas fa-download'></i> Download File
//               </button>
//             </div>
//           ) : (
//             <div className='image-upload-container'>
//               <div style={fileInputStyle}>
//                 <input
//                   name={name}
//                   type='file'
//                   accept={this.props.data.fileType || '*'}
//                   className='image-upload'
//                   onChange={this.displayFileUpload}
//                 />
//                 <div className='image-upload-control'>
//                   <div className='btn btn-default'>
//                     <i className='fas fa-file'></i> Upload File
//                   </div>
//                   <p>Select a file from your computer or device.</p>
//                 </div>
//               </div>

//               {this.state.fileUpload && (
//                 <div>
//                   <div className='file-upload-preview'>
//                     <div
//                       style={{ display: 'inline-block', marginRight: '5px' }}
//                     >
//                       {`Name: ${this.state.fileUpload.name}`}
//                     </div>
//                     <div style={{ display: 'inline-block', marginLeft: '5px' }}>
//                       {this.state.fileUpload.size.length > 6
//                         ? `Size:  ${Math.ceil(
//                             this.state.fileUpload.size / (1024 * 1024)
//                           )} mb`
//                         : `Size:  ${Math.ceil(
//                             this.state.fileUpload.size / 1024
//                           )} kb`}
//                     </div>
//                   </div>
//                   <br />
//                   <div
//                     className='btn btn-file-upload-clear'
//                     onClick={this.clearFileUpload}
//                   >
//                     <i className='fas fa-times'></i> Clear File
//                   </div>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

const FileUpload = (props) => {
  const [fileUpload, setFileUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const { axiosRequest } = useApiClient();
  const fileInputRef = useRef(null);

  const getFileTypeName = (type) => {
    const fileTypes = [
      { type: "image", typeName: "Image" },
      { type: "application/pdf", typeName: "PDF" },
      {
        type: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        typeName: "Word",
      },
      {
        type: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        typeName: "Excel",
      },
      {
        type: "image, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        typeName: "All File Type",
      },
      // {
      //   type: "application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation",
      //   typeName: "Powerpoint",
      // },
      // {
      //   type: "video/mp4,video/x-m4v,video/*",
      //   typeName: "Videos",
      // },
    ];
    const foundType = type
      ? fileTypes.find((file) => file.type.includes(type))
      : "";
    return foundType ? foundType.typeName : "";
  };

  const displayFileUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      //validating
      if (file) {
        const allowedExtensions = {
          Image: ["jpg", "jpeg", "png"],
          PDF: ["pdf"],
          Word: ["doc", "docx"],
          Excel: ["xls", "xlsx", "csv"],
        };
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        let selectedFileType = null;
        const type = props.data.fileType
          ? getFileTypeName(props.data.fileType)
          : "All File Type";
        // console.log("type ", props.data.fileType)
        // console.log("type type ", type)
        const MaxFileSize = 5000000;
        if (file?.size > MaxFileSize) {
          const msg = `File Size should be less than 5MB`;
          props?.handleNotificationMessage(msg, "error");
          e.target.value = null; // Reset the input field
          return;
        }
        if (!type || type === "All File Type") {
          for (const [typeVal, extensions] of Object.entries(
            allowedExtensions
          )) {
            if (extensions?.includes(fileExtension)) {
              selectedFileType = typeVal;
              break;
            }
          }
        } else {
          if (allowedExtensions?.[type]?.includes(fileExtension)) {
            selectedFileType = type;
          }
        }
        if (selectedFileType) {
          setFileUpload(file);
        } else {
          let msg = "";
          if (type != "All File Type") {
            msg = `Please select a valid ${type} File`;
          } else {
            msg = `Please select any valid Image/PDF/Word/Excel File`;
          }
          props?.handleNotificationMessage(msg, "error");
          e.target.value = null; // Reset the input field
          return;
        }
      }

      const formData = new FormData();
      formData.append(`${props?.data?.field_name}`, file); // 'file' is the key
      setLoading(true);

      await axiosRequest.post(`Forms/FormInstance/upload`, formData, {
        successCallBack: (response) => {
          props?.handleFileChange(response?.data?.FileId);
          setLoading(false);
          e.target.value = null;
        },
        errorCallBack: (response) => {
          props?.handleNotificationMessage(
            response?.response?.data?.Messages?.[0] ||
            response?.data?.Messages?.[0] ||
            "Internal server error",
            "error"
          );
          clearFileUpload();
          setLoading(false);
          e.target.value = null;
        },
      });
    }
  };

  const triggerFileInputClick = () => {
    fileInputRef.current.click(); // Programmatically trigger file input click
  };

  const clearFileUpload = () => {
    setFileUpload(null);
  };

  const saveFile = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;
    const response = await fetch(sourceUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      responseType: "blob",
    });
    const dispositionHeader = response.headers.get("Content-Disposition");
    const resBlob = await response.blob();
    // Assumes saveAs is globally available or imported
    const blob = new Blob([resBlob], {
      type: props.data.fileType || response.headers.get("Content-Type"),
    });
    if (dispositionHeader && dispositionHeader.indexOf(";filename=") > -1) {
      const fileName = dispositionHeader.split(";filename=")[1];
      saveAs(blob, fileName);
    } else {
      const fileName = sourceUrl.substring(sourceUrl.lastIndexOf("/") + 1);
      saveAs(blob, fileName);
    }
  };

  let baseClasses = "SortableItem rfb-item";
  const name = props.data.field_name;
  const fileInputStyle = fileUpload ? { display: "none" } : null;
  if (props.data.pageBreakBefore) {
    baseClasses += " alwaysbreak";
  }
  return (
    <div style={{ ...props.style }} className={baseClasses}>
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        {props.read_only === true &&
          props.defaultValue &&
          props.defaultValue.length > 0 ? (
          <div>
            <button className="btn btn-default" onClick={saveFile}>
              <i className="fas fa-download"></i> Download File
            </button>
          </div>
        ) : (
          <div className="image-upload-container cursor-pointer">
            {loading ? (
              <>
                {/* Overlay with opacity */}
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Opacity 0.5
                    zIndex: 999, // Ensure it's on top of the page
                    cursor: "not-allowed", // Prevent interaction
                  }}
                />
                {/* Loader */}
                <Loader
                  type="infinite-spinner"
                  className="loader-full-screen"
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000, // Ensure loader is on top of the overlay
                  }}
                />
              </>
            ) : null}

            <div style={fileInputStyle} onClick={triggerFileInputClick}>
              <input
                name={name}
                type="file"
                ref={fileInputRef} // Attach the ref to the file input
                accept={props.data.fileType || "*"}
                className="image-upload"
                onChange={displayFileUpload}
                onClick={(e) => e.stopPropagation()}
              />
              <div className="image-upload-control">
                <div className="btn btn-default btnuploadform">
                  <div className="uploadformicon">
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M7 10V9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9V10C19.2091 10 21 11.7909 21 14C21 15.4806 20.1956 16.8084 19 17.5M7 10C4.79086 10 3 11.7909 3 14C3 15.4806 3.8044 16.8084 5 17.5M7 10C7.43285 10 7.84965 10.0688 8.24006 10.1959M12 12V21M12 12L15 15M12 12L9 15"
                          stroke="#000000"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </div>
                  Upload File
                </div>
                <p className="formuploadpar">
                  {`Select a file from your computer or device.`}
                  <br />
                  <small>
                    <b>File Type{" : "}</b>
                    {FileTypes?.find((i) => i?.value === props.data.fileType)
                      ?.label ||
                      "Accept all file types with a maximum file size of 5MB"}
                  </small>
                </p>
              </div>
            </div>

            {fileUpload && (
              <div>
                <div className="file-upload-preview">
                  <div style={{ display: "inline-block", marginRight: "5px" }}>
                    {`Name: ${fileUpload.name}`}
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    {fileUpload.size > 1024 * 1024
                      ? `Size: ${Math.ceil(fileUpload.size / (1024 * 1024))} MB`
                      : `Size: ${Math.ceil(fileUpload.size / 1024)} KB`}
                  </div>
                </div>

                <div
                  className="btn btn-file-upload-clear"
                  onClick={clearFileUpload}
                >
                  <i className="fas fa-times"></i> Clear File
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


BaseFormELements.Header = Header;
BaseFormELements.Paragraph = Paragraph;
BaseFormELements.Label = Label;
BaseFormELements.LineBreak = LineBreak;
BaseFormELements.TextInput = TextInput;
BaseFormELements.EmailInput = EmailInput;
BaseFormELements.PhoneNumber = PhoneNumber;
BaseFormELements.NumberInput = NumberInput;
BaseFormELements.TextArea = TextArea;
BaseFormELements.Dropdown = Dropdown;
BaseFormELements.Signature = Signature;
BaseFormELements.Checkboxes = Checkboxes;
BaseFormELements.DatePicker = DatePicker;
BaseFormELements.RadioButtons = RadioButtons;
BaseFormELements.FileUpload = withNotification(FileUpload);

export default BaseFormELements;
