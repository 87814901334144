import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  ComboBox,
  DropDownList,
  ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import Auth from "components/authcomponent/Auth";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { convertToTimezone, getCompleteDate, getFullDate, getTimeFormat, getTimeZoneShortHand } from "utils";
import withNotification from "components/Hoc/withNotification";
import { Loader } from "@progress/kendo-react-indicators";
import MemberSelection from "components/submemberseletion/MemberSelection";
import ClientNameWithMemberType from "components/summary/ClientNameWithMemberType";
import ClassSessionItem from "./ClassSessionItem";
import usePurchaseClassList from "./PurchaseClassList.hook";
import { GetLocalStore } from "utils/storage";
import { useHistory } from "react-router-dom";
import { VirtualScroll } from "./VirtualScroll";
const findItem = (obj: any, arr: any) => {
  let allVals: any[] = [];
  arr.forEach((val: any) => {
    if (val?.values) {
      allVals.push(...val.values);
    }
  });

  const findVal = allVals.findIndex((item: any) => {
    return item.ClassSessionId === obj.ClassSessionId;
  });
  return findVal;
};
const MyItemRender = React.memo(({ dataItem, redeem = false, propItems, usePackageFilter, handleSelectClass, handleCollapse, handleExpand, expandedItem, selectedClasses, handleCopyClick }: any) => {
  let item = dataItem;
  if (item && item.values && item.values.length > 0) {
    return (
      <>
        <div
          className="text-center text-lg-center text-md-center bz-fw-600 mb-2"
          key={item?.date?.toString()}
        >
          <div className="text-capitalize">{`Group On ${getFullDate(
            item?.date
          )} ${getTimeZoneShortHand(GetLocalStore("tenantUserSession")?.TimeZone?.Id)}`}</div>
        </div>
        {item.values.map((item1: any, indx: number) => {
          const check = findItem(item1, selectedClasses);
          return (
            <ClassSessionItem
              staffPage={propItems?.staffPage}
              dataItem={item1}
              check={check}
              redeem={redeem}
              usePackageFilter={usePackageFilter}
              handleSelectClass={handleSelectClass}
              key={`${item1.ClassSessionId}${check}`}
              handleCollapse={handleCollapse}
              handleExpand={handleExpand}
              expandedItem={expandedItem}
              handleCopyClick={handleCopyClick}
            />
          );
        })}
      </>
    );
  } else {
    return <></>;
  }
});
const RenderClassSessionsList = (
  {
    props,
    isClassLoading, classSessionsList,
    selectedPackage,
    pageLoading,
    usePackageFilter,
    handleSelectClass,
    handleCollapse,
    handleExpand,
    expandedItem,
    selectedClasses,
    handlePageChange,
    handleCopyClick

  }: any) => {
  if (isClassLoading) {
    return <BuzopsLoader type={"list"} />;
  } else if (classSessionsList.classSessionData.length === 0) {
    return (
      <>
        <div className={"bz-no-upcoming-appoinments text-muted"}>
          No Group Available{" "}
        </div>
      </>
    );
  }
  let redeem = false;
  if (selectedPackage || props?.selectedPackage) {
    redeem = true;
  }
  return (
    <>
      <div className="groupclasslistadmin">
        <ListView
          item={(propvalues) => (
            // <VirtualScroll height={120}>
            <MyItemRender
              dataItem={propvalues?.dataItem}
              redeem={redeem}
              propItems={props}
              usePackageFilter={usePackageFilter}
              handleSelectClass={handleSelectClass}
              handleCollapse={handleCollapse}
              handleExpand={handleExpand}
              handleCopyClick={handleCopyClick}
              expandedItem={expandedItem}
              selectedClasses={selectedClasses}
              key={propvalues?.dataItem?.ClassId}
            />
            // </VirtualScroll>

          )}
          // onScroll={(e) => scrollHandler(e)}
          data={classSessionsList.classSessionData || []}
          style={{
            width: "100%",
          }}

        />
        {!props?.classSessionId ?
          <div className="d-flex justify-content-center align-items-center bz-services-showMore">
            <div >
              {pageLoading ? (
                <>
                  <Loader type="converging-spinner" />
                </>
              ) : (
                <>
                  {classSessionsList?.startDate ? <span
                    className="btn btn-link  align-baseline bz-cursor-pointer p-0"
                    onClick={() => handlePageChange()}
                  >
                    Next Schedule <i className="fas fa-caret-down"></i>
                  </span> : null}
                </>
              )}
            </div>
          </div> : null}
      </div>
    </>
  );
};

const PurchaseClassList = (props: any) => {
  const history = useHistory();

  const {
    PackagesData,
    AllClientsList,
    clientFormValues,
    toggleClientConfirmDialog,
    handleSubmit,
    isLoggedIn,
    clientSubmitDetails,
    handleClientSelection,
    selectedClassLength,
    handleClearAllSlots,
    selectedClasses,
    handleCloseLogin,
    loginStatus,
    confirmClientDialog,
    handleClientConfirmation,
    classesList,
    handleFilter,
    ClassNamefilterChange,
    filterContent,
    isClassLoading,
    classSessionsList,
    showMoreRef,
    pageLoading,
    handlePageChange,
    handleSelectClass,
    handlePackageChange,
    selectedPackage,
    btnLoading,
    checkZeroDollar,
    checkCompleteReedemViaPackage,
    usePackageFilter,
    handleCollapse,
    handleExpand,
    expandedItem,
    handleUsePackageFilter,
    pickPackageDialog,
    handleClosePickPackageDialog,
    handleCopyClick
  } = usePurchaseClassList(props);



  const packageItemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const item = itemProps.dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${item?.SchedulesToUse + item?.SchedulesUsed
        }`;
    const itemChildren = (
      <span>
        <div className="package-item">
          <div className="package-details bz-payment-options">
            <div className="package-name">
              <span className="primary-heading">{item?.Name}</span>
            </div>
            <div className="package-expiry-schedules">
              <small className="package-schedules ">Redeemed: {`${schedulesToUse}`}</small>
              <br />
              {/* <span> {"|"}</span> */}
              <small className="package-expiry">
                {`${item?.ExpireFrom === null
                  ? "N/A"
                  : moment(item?.ExpireFrom).format("L")
                  } to ${item?.ExpireOn === null
                    ? "N/A"
                    : moment(item?.ExpireOn).format("L")
                  }`}
              </small>
            </div>
          </div>
        </div>
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  const PackageListDropDown = () => {
    return (
      <ComboBox
        data={PackagesData}
        dataItemKey="PackageInstanceId"
        textField="Name"
        filterable={true}
        placeholder="Filter by package"
        itemRender={packageItemRender}
        style={{ width: "100%" }}
        onChange={handlePackageChange}
        value={selectedPackage || props?.selectedPackage}
      />
    );
  };

  const renderClientConfirmationDialog = () => {
    const selectedClientInfo = AllClientsList?.filter(
      (res: any) => res?.UserMemberId === clientFormValues?.UserMemberId
    )[0];
    return (
      <Dialog
        className={`bz-book-Appoinment-dialog ${btnLoading ? "pe-none" : ""}`}
        title={"Please Confirm"}
        onClose={() => toggleClientConfirmDialog()}
        width={"35%"}

      >
        <div>
          <p className={"dialogContainer"}>
            {`Looks like you have additional family members on account, please confirm you are making purchases for the below client.`}
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <ClientNameWithMemberType
              clientName={selectedClientInfo?.FullName}
              memberType={selectedClientInfo?.MemberType}
            />
          </div>
          <div className="column-button-section book-appt-btns">
            <Button primary={false} onClick={() => toggleClientConfirmDialog()}>
              No
            </Button>
            <BuzopsButton label={"Yes"} onClick={() => handleSubmit()} disabled={btnLoading}
              loading={btnLoading}
              key={`${btnLoading}btn-yes`} />
          </div>
        </div>
      </Dialog>
    );
  };



  const selectedItemRender = (props: any) => {
    const item = props.dataItem;

    return item?.values.length > 0 ? (
      <>
        <div className="card bz-upcoming-apponments-sm-card mb-2">
          <div className="card-body py-2 px-0">
            <div className="media">
              <div className="media-body">
                <div>
                  <div className="mt-0 mb-2 text-capitalize">
                    <strong>{item?.className}</strong>
                  </div>

                  {item?.values.map((item1: any, indx: number) => {
                    return (
                      <div
                        className="bzc-classes-selected-time"
                        key={item1?.ClassSessionId?.toString()}
                      >
                        <div>
                          <span className="text-muted text-uppercase d-block mb-0">
                            {" "}
                            <i className="far fa-clock"></i>
                            {getTimeFormat(item1?.ClassSessionStartDate)} -{" "}
                            {getTimeFormat(item1?.ClassSessionEndTime)}
                          </span>
                          <span className="text-muted text-capitalize d-block mb-0">
                            <i className="far fa-calendar-alt"></i>
                            {getCompleteDate(item1?.ClassSessionStartDate)}
                            {item1?.SelectedPackage && usePackageFilter ? <small className="packageavailable">Package Selected ({`${item1?.SelectedPackage?.Name}`})</small> : null}
                            {item1?.CanBookAsWaitlist ? <span className="badge bz-badge-warning ml-1">Waitlisted</span> : null}
                          </span>
                          <span
                            className="bz-close-icon2"
                            onClick={() => {
                              handleSelectClass(item1, "delete");
                            }}
                          >
                            <i className="far fa-trash-alt"></i>
                          </span>
                        </div>

                        <hr
                          className={`${indx + 1 !== item.values.length
                            ? "show my-2"
                            : "hide"
                            }`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
  };




  const filterClassSessionList = () => {
    return (
      <>
        <div className=" bz-purchase-class-filter mb-3 mt-2">
          <div className="row">
            {
              selectedPackage &&
              <div className="col-sm-12 col-xl-12  text-left " style={{ alignSelf: "center" }}>
                <br className="d-none d-xl-inline" />
                <div>
                  <label>Enrollment Indicator:</label> &nbsp;
                  {`${selectedPackage?.SessionType === 2 ? "Unlimited" : `${(selectedPackage?.SchedulesToUse)}/${selectedPackage?.SchedulesToUse + selectedPackage?.SchedulesUsed} left`} `}
                </div>
              </div>
            }
            <div className="col-md-12 col-xl-12 col-lg-12">

              <div className="row">
                {isLoggedIn && clientSubmitDetails?.UserMemberId && (
                  <>
                    {!props?.selectedPackage && (
                      <div className="col-md-12 col-lg-12 col-xl-12  row">
                        <div className="col-sm-6 col-xl-6 order-2 order-xl-1 packageselection mb-3">
                          {/* <label>Package:</label> */}
                          {PackageListDropDown()}
                        </div>

                        <div className="col-sm-6 col-xl-6 order-3 order-xl-3 mt-0 mt-sm-0 mb-3">
                          {/* <label>Purchase/Enrollment For:</label> */}
                          <MemberSelection
                            allClientsList={AllClientsList}
                            selectedUsermemberId={clientSubmitDetails?.UserMemberId}
                            handleUpdateClient={(val: any) => handleClientSelection(val)}
                          />
                        </div>
                      </div>
                    )}


                  </>
                )}
                <div className="col-md-12 col-lg-12 col-xl-12  row ">
                  <div className="col-md-4 mb-3">
                    {!props?.classShortName && (
                      <div className="mb-0 mb-sm-0 mr-sm-0 groupdropwnlis">
                        <DropDownList
                          data={classesList}
                          textField="Name"
                          dataItemKey="ClassId"
                          id={"class"}
                          name={"class"}
                          onChange={(e) => handleFilter(e.value, "class")}
                          value={filterContent.class}
                          // defaultItem={"All Group"}
                          filterable={true}
                          onFilterChange={(e) => ClassNamefilterChange(e)}
                          disabled={isClassLoading}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mb-3">
                    <div >
                      {" "}
                      <DatePicker
                        name="fromDate"
                        id="fromDate"
                        min={!props.staffPage ? convertToTimezone(new Date(), true) : new Date()}
                        value={filterContent.fromDate}
                        onChange={(e) => handleFilter(e.target.value, "fromDate")}
                        disabled={isClassLoading}
                      />

                    </div>
                  </div>

                  <div className="col-md-4 text-right mb-3">
                    {!selectedPackage && !props?.selectedPackage && PackagesData?.length > 0 ? (
                      <div className="justify-self-end">
                        {!usePackageFilter ? (
                          <Button
                            onClick={() => handleUsePackageFilter(true)} className="bnt-radiusaddpackage noaddpck maxwithpackage"
                          >
                            Use Package
                          </Button>
                        ) : (
                          <Button
                            onClick={() => handleUsePackageFilter(false)} className="bnt-radiusaddpackage  maxwithpackage k-primary"
                          >
                            Using Package
                          </Button>

                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {
                selectedPackage &&
                <div>
                  {showPackageMessage()}

                </div>
              }

            </div>

          </div>


        </div>
      </>
    );
  };
  const PickPackagesItemRender = (props: any) => {
    const item = props.dataItem;
    const schedulesToUse =
      item.SessionType === 2
        ? "Unlimited"
        : `${item?.SchedulesUsed} of ${item?.SchedulesToUse + item?.SchedulesUsed
        }`;
    return (
      <div className="card mb-3 packagelistview pointer appthover"
        onClick={() => handleSelectClass(pickPackageDialog?.selectedGroup, "add", item)}
      >
        <div className="card-body">
          <div className="package-details">
            <div className="package-name">
              <span className="primary-heading">{item?.Name}</span>
            </div>
            <div className="package-expiry-schedules">
              <small className="package-schedules">
                Redeemed: {`${schedulesToUse}`}
              </small>
              <br />
              {item?.SessionType !== 3 && (
                <small className="package-expiry">
                  {`${item?.ExpireFrom === null
                    ? "N/A"
                    : moment(item?.ExpireFrom).format("L")
                    } to ${item?.ExpireOn === null
                      ? "N/A"
                      : moment(item?.ExpireOn).format("L")
                    }`}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };


  const PickPackagesDialog = () => {
    const filteredPackages = pickPackageDialog?.selectedGroup?.PackagesList
    return (
      <Dialog title={"Packages"} onClose={handleClosePickPackageDialog} width={"35%"} height={'550px'}>
        <ListView
          data={filteredPackages || []}
          item={PickPackagesItemRender}
          style={{ width: "100%" }}
        />
      </Dialog>
    );
  };

  const handleNavigateToParentGroup = () => {
    history.push(`/${props?.classShortName}`)
  }

  const showPackageMessage = () => {
    return <div className="alert alert-warning fade show mt-3 mb-0"
      role="alert">
      {props?.selectedPackage ? <strong>Note: Only classes from your purchased group package are shown.</strong> : <strong>Note: Only classes from your purchased group package are shown. Deselect the package to view all.</strong>}
    </div>
  }

  if (
    props?.classShortName &&
    !props?.ShortCodeStatus
  ) {
    let message = "The Group not available to book/enroll";
    return (
      <>
        <div className="row">
          <div className={"d-flex justify-content-start mt-3"}>
            <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
          </div>
        </div>
        <br />{" "}
        <div className={"bzo-services-container border-0 text-muted"}>
          {message}
        </div>
      </>
    );
  }


  if (
    props?.classShortName &&
    props?.ShortCodeStatus &&
    props?.ShortCodeDescription
  ) {
    let message = <div className={"bzo-services-container border-0 text-muted"}>The Appointment not available to book/enroll</div>
    switch (props?.ShortCodeDescription) {
      case "Expired":
        message = <div className={"bzo-services-container border-0 text-muted"}>The appointment has been completed. <span className="btn btn-link text-decoration-underline" onClick={() => handleNavigateToParentGroup()}>Click here</span>  to schedule future appointments for the same group. Thank you!</div>
        break;
      case "Cancelled":
        message = <div className={"bzo-services-container border-0 text-muted"}>The appointment has been cancelled. <span className="btn btn-link text-decoration-underline" onClick={() => handleNavigateToParentGroup()}>Click here</span> to schedule future appointments for the same group. Thank you!</div>
        break;
      default:
        break;
    }
    return (
      <>
        <div className="row">
          <div className={"d-flex justify-content-start mt-3"}>
            <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
          </div>
        </div>
        <br />{" "}
        {message}

      </>
    );
  }
  const footerAdjustmentClass = classSessionsList?.classSessionData?.length <= 5 ? "bz-footer-adjustment" : "";

  return (
    <>


      <div
        className={
          props.page == "online" ? "bz-online-classes" : "bz-inhouse-classes"
        }
      >
        <div className={`bzc-purchase-class-list-view bz-purchaseclass-scroll ${footerAdjustmentClass}`}>
          <div
            className={
              selectedClassLength() > 0
                ? "bzc-purchase-class-list-row bz-class-selected"
                : "row"
            }
          >
            <div
              className={
                selectedClassLength() > 0
                  ? "bzc-left-side-card bz-groupservices-left-side-card"
                  : "col-xs-12 col-md-12 col-md-12 col-lg-12"
              }
            >
              <Card className="my-0">
                {props?.classShortName && props?.classShortName !== null && (
                  <h5 className="card-title bzo-primary-heading mb-0">Group</h5>
                )}
                <CardBody className="bz-purchase-class-list p-0 groupappadmin">
                  {!props?.classSessionId ? filterClassSessionList() : null}
                  {/* {RenderClassSessionsList()} */}
                  <RenderClassSessionsList
                    props={props}
                    isClassLoading={isClassLoading}
                    classSessionsList={classSessionsList}
                    selectedPackage={selectedPackage}
                    pageLoading={pageLoading}
                    usePackageFilter={usePackageFilter}
                    handleSelectClass={handleSelectClass}
                    handleCollapse={handleCollapse}
                    handleExpand={handleExpand}
                    expandedItem={expandedItem}
                    selectedClasses={selectedClasses}
                    handlePageChange={handlePageChange}
                    handleCopyClick={handleCopyClick}
                  />
                </CardBody>
              </Card>
            </div>
            <div
              className={
                selectedClassLength() > 0 ? "bzc-right-side-card" : "d-none"
              }
            >
              <div
                className={`card bz-cart-card bz-shadow-class ${props.page !== "inhouse"
                  ? "bz-shadow-class-online"
                  : "bz-shadow-class-inhouse"
                  }`}
              >
                <div className="card-header">
                  Cart ({selectedClassLength()})
                  <span
                    className="bz-close-icon-new float-right"
                    role={"button"}
                    title={"Clear All"}
                    onClick={() => handleClearAllSlots()}
                  >
                    <i
                      className="far fa-trash-alt"
                      style={{ fontSize: "1rem" }}
                    ></i>
                  </span>
                </div>
                <div className="card-body" style={{ overflow: "auto" }}>
                  <ListView data={selectedClasses} item={selectedItemRender} />
                </div>

                <div className="d-flex justify-content-end p-2">
                  {(selectedPackage || props?.selectedPackage || checkCompleteReedemViaPackage() || (props?.staffPage && checkZeroDollar())) ? <BuzopsButton
                    disabled={btnLoading}
                    loading={btnLoading}
                    label={"Enroll"}
                    onClick={() => handleClientConfirmation()}
                  /> : <BuzopsButton
                    label={"Proceed to Buy"}
                    onClick={() => handleClientConfirmation()}

                  />}
                </div>
                {(selectedPackage || props?.selectedPackage || (props?.staffPage && checkZeroDollar())) && (
                  <div className="d-flex justify-content-end p-2 px-3">
                    <span className="">
                      {" "}
                      {props?.staffPage && checkZeroDollar() ?
                        <small className="session-redeem">
                          Note: {`Above selected Appointments are unpaid appointments can be enrolled directly`}
                        </small> : <small className="session-redeem">
                          Note: {`Above selected Appointments will be redeemed with `}
                          <u>{`${selectedPackage?.Name || props?.selectedPackage?.Name}.`}</u>
                        </small>
                      }
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {confirmClientDialog && renderClientConfirmationDialog()}
      {pickPackageDialog?.showDialog &&
        PickPackagesDialog()}
      <Auth
        {...props}
        handleCloseLogin={() => handleCloseLogin()}
        showComponent={loginStatus}
        key={loginStatus.toString()}
      />
    </>
  );
};

export default withNotification(PurchaseClassList);
